.wfh-capture {
  .today-attendance {
    min-height: 0px !important;

    .today-attendance-info {
      text-align: center;
      min-height: 0px !important;
      padding: 8px 15px !important;

      p {
        margin: 8px 8px !important;
      }

      .no-record {
        font-weight: bold;
        color: #ff5722;

        svg {
          color: #ff5722;
          display: inline-block;
          text-align: center;
          width: 20px;
        }
      }

      .attendance-hint {
        font-size: 13px;
      }

      .entry-info {
        display: flex;
        flex-direction: row;
        align-items: center;

        .entry-title {
          flex: 1;
          margin-right: 8px;

          svg {
            color: #2e7d32;
          }
        }

        .entry-time {
          color: #2e7d32;
          font-weight: bold;
        }

        .attendances-time {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .entry,
          .exit {
            font-size: 13px;
            font-weight: bold;
            border-radius: 4px;
            margin: 0px 0px 4px;
            color: white;
            padding: 4px 8px;
            display: block;
          }

          .entry {
            background: green;
          }

          .exit {
            background: orange;
          }
        }
      }
    }
  }

  .video-container {
    flex: 1;
    background: white;
    position: relative;
    // overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    max-width: 1024px;

    >video {
      //   top: 0;
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      max-width: 1000px !important;
      max-height: 380px;
      //   margin: auto;
    }

    .capture-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute !important;
      bottom: 30px;
      left: 20%;
      width: 60px;
      height: 60px;
      right: 20%;
      text-align: center;
      border-top-right-radius: 100%;
      border-top-left-radius: 100%;
      border-bottom-right-radius: 100%;
      border-bottom-left-radius: 100%;
      background: white;
      border: 1px solid #00000054;
      justify-content: center;
      margin: auto;

      svg {
        color: #000000a3;

        font-size: 32px;
        margin: 12px 12px;
      }

      span {
        color: #444;
        display: inline-block;
        margin: 12px 0px;
        font-size: 28px;
        font-weight: 400;
        margin-left: 8px;
      }
    }
  }
}

.confirmation-modal {
  position: absolute;
  top: 0px;
  right: 0px;
}