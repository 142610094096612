.leave-section-container {
    .leave-section {
        height: 100%;
        overflow-y: auto;

        .leave-list-item {
            display: flex;
            flex-direction: row;

            >div {}

            .list-meta {
                flex: 1;

                h1,
                h2,
                h3,
                h4,
                h5,
                p,
                h6 {
                    margin: 0px;
                }

                h4 {
                    margin: 4px 8px;
                    font-weight: 600;
                }

                h5 {
                    margin: 4px 8px;
                    font-weight: 400;
                }

                p {
                    margin: 4px 8px;
                }
            }

            .list-time {
                >span {
                    display: block;

                    .fa,
                    svg {
                        display: inline-block;
                        width: 23px;
                    }
                }

                .enter-time {
                    background-color: #2e7d32;
                    color: white;
                    padding: 2px 8px 2px 6px;
                    margin: 2px;
                    border-radius: 3px;
                }

                .enter-time.late {
                    background-color: red;
                    color: white;
                }

                .exit-time {
                    background-color: #2e7d32;
                    color: white;
                    padding: 2px 8px 2px 6px;
                    margin: 2px;
                    border-radius: 3px;
                }

                .exit-time.early {
                    background-color: red;
                    color: white;
                }
            }
        }

        .leave-list-item {
            padding-bottom: 6px;
            border-bottom: 1px solid #dedede;
        }

        .leave-list-item:last-child {
            border-bottom-color: transparent;
        }
    }
}