.main-window > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.attendance-wfo {
  .maps-container {
    flex: 1;
    background: #333;
    position: relative;
    overflow: hidden;
    height: 400px;

    .checkin-information {
      position: absolute !important;
      bottom: 30px;
      left: 10px;
      right: 10px;
      box-shadow: 1px 2px 4px black;
    }

    .capture-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute !important;
      bottom: 30px;
      left: 20%;
      width: 60px;
      height: 60px;
      right: 20%;
      text-align: center;
      border-top-right-radius: 100%;
      border-top-left-radius: 100%;
      border-bottom-right-radius: 100%;
      border-bottom-left-radius: 100%;
      background-color: rgb(112, 32, 131);
      box-shadow: 0px 2px 2px #333;
      border: 1px solid rgb(132, 52, 151);
      justify-content: center;
      margin: auto;

      svg {
        color: white;
        font-size: 32px;
        margin: 12px 12px;
      }

      span {
        color: #444;
        display: inline-block;
        margin: 12px 0px;
        font-size: 28px;
        font-weight: 400;
        margin-left: 8px;
      }
    }
  }
  .attendance-table {
    width: 100%;
    th,
    td {
      text-align: center;
      font-size: 14px;
    }
  }
}
