html,
body,
#root,
.main-window {
	height: 100%;
}

.information-text {
	margin: 6px;
	padding: 6px;
}

.information-text.yellow {
	background-color: #ffca28;
	border: 1px solid #ffc107;
	border-radius: 4px;
}

.alert-enter {
	opacity: 0;
	transform: scale(0.9);
}

.alert-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}

.alert-exit {
	opacity: 1;
}

.alert-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.text-white,
.text-white * {
	color: white !important;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.main-window {
	display: flex;
	flex-direction: column;
}

.align-center {
	text-align: center;
}

.sub-title {
	color: #888;
	font-size: 14px;
	padding: 15px 0 9px 15px;
}

.sub-title.errors {
	color: red;
}

.title {
	color: #333;
	font-size: 18px;
	padding: 15px 0 9px 15px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
